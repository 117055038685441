import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { MsalGuard } from "@azure/msal-angular";

const routes: Routes = [
  {
    path: "",
    redirectTo: "vote",
    pathMatch: "full" },
  {
    path: "vote",
    canActivate: [MsalGuard],
    loadChildren: () => import("./vote/vote.module").then(m => m.VoteModule),
  },
  {
    path: "thank-you",
    canActivate: [MsalGuard],
    loadChildren: () => import("./thank-you/thank-you.module").then(m => m.ThankYouModule) },
  {
    path: "no-access",
    loadChildren: () => import("./no-access/no-access.module").then(m => m.NoAccessModule) },
  {
    path: "already-voted",
    canActivate: [MsalGuard],
    loadChildren: () => import("./already-voted/already-voted.module").then(m => m.AlreadyVotedModule),
  },
  {
    path: "**",
    redirectTo: "vote",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
