export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Outil PeV",
  "LOGIN_PAGE": {
    "VOTE_OVER": {
      "TITLE": "Le délai pour voter a expiré.",
      "TEXT": "Les résultats des élections seront publiés mi-novembre.",
    },
    "LOGIN_FORM": {
      "TITLE": "Enregistrez-vous pour élire la commission du personnel.",
      "LOGIN": "Connexion",
      "ERROR_MESSAGE": "Les données d’accès que vous avez saisies sont erronées.",
      "NO_CODE": "Vous n’avez pas reçu de code?",
      "MAIL": {
        "ADDRESS": "SPEKOWahlausschuss@bkw.ch",
        "SUBJECT": "Vous n’avez pas reçu de code?",
      },
      "PLACEHOLDER": {
        "USERNAME": "Nom d’utilisateur",
        "PASSWORD": "Mot de passe",
      },
    },
  },
  "THANK_YOU_PAGE": {
    "TITLE": "Merci d’avoir voté.",
    "TEXT": "Les résultats des élections seront publiés mi-novembre.",
  },
  "NO_ACCESS_PAGE": {
    "TITLE": "Vous n’avez pas le droit de voter.",
    "TEXT_BOLD": "Malheureusement, vous ne pouvez pas participer au vote ",
    "TEXT_REGULAR": "parce que vous ne faites pas partie des collaborateurs ayant le droit de vote.",
  },
  "ALREADY_VOTED_PAGE": {
    "TITLE": "Vous ne pouvez plus vous connecter parce que vous avez déjà voté.",
    "TEXT": "Les résultats des élections seront publiés mi-novembre.",
  },
  "VOTE_PAGE": {
    "TITLE": "Élisez la nouvelle représentation du personnel (PeV)",
    "STEP_1": {
      "TITLE": "Pour votre information",
      "PARAGRAPH_1": "Afin de constituer une représentation régionale et transversale du personnel, nous avons réparti les candidats et candidates en trois sections électorales. Chacune d’entre elles sera pourvue entre 4 et 7 sièges prédéfinis et subdivisée en deux sous-circonscriptions pour assurer la meilleure représentation possible.",
      "PARAGRAPH_2": "Les deux candidats ayant recueilli le plus de voix dans les sous-sections électorales seront élus. Les un à deux sièges restants par circonscription sont occupés par les personnes qui ont obtenu le nombre de voix suivant.",
      "PARAGRAPH_LIST": {
        "TITLE": "Voici comment fonctionne l'élection",
        "CONTENT": "<li>Tous les collaborateurs à plein temps de BKW Energie SA engagés pour une durée indéterminée ont le droit de vote et d'éligibilité, à l'exception de la direction du groupe, des collaborateurs des niveaux 1 et 2, des collaborateurs de la direction des ressources humaines et des membres du comité électoral. </li>" +
            "<li>Vous pouvez voter pour tous les candidats, pas seulement ceux de votre circonscription. </li>" +
            "<li>Vous pouvez exprimer autant de votes par circonscription que la circonscription compte de sièges au comité du personnel</li>" +
            "<li>Distribuez les votes à tous les candidats de votre circonscription.</li>" +
            "<li>Si possible, distribuez tous vos votes, mais ce n'est pas une obligation.</li>" +
            "<li>Vous ne pouvez voter qu'une seule fois pour chaque candidat.</li>" +
            "<li>Les candidats peuvent également voter pour eux-mêmes.</li>" +
            "<li>Une fois que vos votes ont été distribués et exprimés, vous ne pouvez pas changer l'élection.</li>",
      },
      "PARAGRAPH_3": "Veuillez élire la nouvelle représentation du personnel parmi les candidates et les candidats suivant(e)s.",
    },
    "STEP_2": {
      "HEADING": "Première section électorale: <b>(5 sièges)</b>",
      "TITLE": "Section électorale Siège",
      "INFORMATION": "Vous pouvez sélectionner un maximum de 5 personnes dans cette section électorale en cochant leur nom",
    },
    "STEP_3": {
      "HEADING": "Deuxième section électorale: <b>(4 sièges)</b>",
      "TITLE": "Section électorale Production",
      "INFORMATION": "Vous pouvez sélectionner un maximum de 4 personnes dans cette section électorale en cochant leur nom",
    },
    "STEP_4": {
      "HEADING": "Troisième section électorale: <b>(7 sièges)</b>",
      "TITLE": "Section électorale E et N",
      "INFORMATION": "Vous pouvez sélectionner un maximum de 7 personnes dans cette section électorale en cochant leur nom",
    },
    "VOTE_CANCEL": {
      "TITLE": "Vous avez interrompu le vote.",
      "TEXT": "Vous pouvez voter jusqu’au {{date}} inclus.",
    },
    "MAX_EXCEEDED": "Dans la section électorale {{voteRound}}, un maximum de {{maxNumber}} votes peuvent être exprimés.",
    "VOTES_REMAINING": "Vous pouvez encore exprimer <b>{{number}} sur {{maxNumber}} votes</b> dans cette section électorale",
  },
  "BUTTON": {
    "NEXT": "Suivant",
    "VOTE": "Votez maintenant",
    "DECLINE_VOTE": "Interrompre le vote",
    "CLOSE": "Fermer",
    "BACK": "Retour",
  },
  "CANDIDATE_PAGE": {
    "PROFILE_LINK": "Voir le profil",
  },
  "CANDIDATE_DIALOG": {
    "BIRTHDATE": "Année de naissance:",
    "ENTRY_DATE_BKW": "Date d’entrée chez BKW:",
    "MOTIVATION": "Motivation:",
    "PEV_STATE": "Statut PEV:",
  },
  "SHARED": {
    "PEV_STATE_NEW": "Nouveau",
    "PEV_STATE_OLD": "Jusqu’ici",
    "ERROR_MESSAGE": "Oh, quelque chose semble ne pas avoir fonctionné comme prévu… Indiquez-<a href=\"mailto:SPEKOWahlausschuss@bkw.ch?subject=Fehlerbeschreibung Wahltool\" target=\"_blank\">nous</a> le problème que vous avez rencontré pour que nous puissions le résoudre aussi rapidement que possible.",
  },
};
