import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";
import { IPevAppConfiguration } from "../configuration";
import { IS_IE } from "./general";

export const T_ENV: IPevAppConfiguration = {
  pekoApiUrl: "https://api-pev-wahlen-t.bkw.ch/api/",
  msalConfig: {
    auth: {
      clientId: "70d13492-c731-4aac-ac39-c23756c88ecd", // jad-pev
      authority: "https://login.microsoftonline.com/bkwfmbenergie.onmicrosoft.com",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: IS_IE, // set to true for IE 11
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    // array declaration as any is a workaround, because new Map() was recognized only as typeof object from msal
    protectedResourceMap: [["https://api-pev-wahlen-t.bkw.ch", ["api://c01fa340-2ffc-4a1b-9002-495d62343462/Read"]]] as any, // jad-pev-api
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "user.read",
        "api://c01fa340-2ffc-4a1b-9002-495d62343462/Read", // jad-pev-api
      ],
    },
    loginFailedRoute: "./no-access",
  },
};
