import { inject, Injectable } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { VoteService } from "./vote.service";
import { catchError, Observable, of, tap } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AlreadyVotedGuardService {

  constructor(private router: Router,
    private voteService: VoteService) {
  }

  canActivate(): Observable<boolean> {
    return this.voteService.hasVoted(this.voteService.username).pipe(
      tap((alreadyVoted) => {
        if (alreadyVoted) {
          this.router.navigate(["already-voted"]);
        }
      }),
      map((alreadyVoted) => !alreadyVoted),
      catchError(() => {
        return of(false);
      }),
    );
  }
}

export const AlreadyVotedGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(AlreadyVotedGuardService).canActivate();
};
