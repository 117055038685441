import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private router: Router;

  constructor(private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService,
    private injector: Injector) {
  }

  handleError(error) {

    // If token is invalid user will get a 401 from L7, then do a logout
    if (error && error.status && error.status === 401) {
      // See https://github.com/angular/angular/issues/9843
      if (!this.router) this.router = this.injector.get(Router);
      this.router.navigate(["login"]);
      // For any other error show general error message
    } else {
      this.bfcNotificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("SHARED.ERROR_MESSAGE"),
      });
    }

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
}
