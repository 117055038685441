import { Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class MSALProvider {

  private msalInstance: IPublicClientApplication;

  constructor(private bfcConfigurationService: BfcConfigurationService) {
    this.msalInstance = new PublicClientApplication(this.bfcConfigurationService.configuration.msalConfig);
  }

  async initialize() {
    try {
      await this.msalInstance.initialize();
      await this.msalInstance.handleRedirectPromise();

      // Set an active account if not already set
      const accounts = this.msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        this.msalInstance.setActiveAccount(accounts[0]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error during MSAL initialization:", error);
    }
  }

  initMSALInstanceFactory(): IPublicClientApplication {
    return this.msalInstance;
  }

  initMSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    // workaround, because new Map() in configuration was recognized only as typeof object from msal
    const config = this.bfcConfigurationService.configuration.msalAngularInterceptorConfig;
    const protectedResourceMap = new Map<string, string[]>();
    protectedResourceMap.set(config.protectedResourceMap[0][0], config.protectedResourceMap[0][1]);

    return {
      interactionType: config.interactionType,
      protectedResourceMap: protectedResourceMap,
    };
  }

  initMSALGuardConfigFactory(): MsalGuardConfiguration {
    return this.bfcConfigurationService.configuration.msalAngularGuardConfig;
  }
}
