import { IPevAppConfiguration } from "../configuration";

export const GENERAL_ENV: IPevAppConfiguration = {
  googleTagManagerContainerId: "GTM-N4VBJZH",
  lastVotingDay: "28.10.2024",
  voteEndDate: "2026-11-21 00:00:01",
  maxVotes: {
    step_2: 5,
    step_3: 4,
    step_4: 7,
  },
};

export const IS_IE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
