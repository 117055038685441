import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { AccountInfo } from "@azure/msal-common";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class VoteService {

  constructor(private bfcConfigurationService: BfcConfigurationService,
    private httpClient: HttpClient,
    private msalService: MsalService) {
  }

  public get username(): string {
    const accounts: AccountInfo[] = this.msalService.instance.getAllAccounts();
    let username: string = "";

    if (accounts?.length > 0) {
      username = accounts[0]?.username;
    }

    return username;
  }

  public vote(candidateIds: number[]): Observable<any> {
    const vote = {
      candidateIds,
      voter: this.username,
      orgUnit: "",
    };

    // Response not mapped to JSON because API does not deliver JSON
    return this.httpClient.post(this.bfcConfigurationService.configuration.pekoApiUrl + "services/voter", vote);
  }

  public hasVoted(voterId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.bfcConfigurationService.configuration.pekoApiUrl + `services/voter/hasVoted/${voterId}`);
  }

  /**
     * Check if the voting window is over (if so -> re-route to vote-over page will happen)
     */
  public isVoteOver(): boolean {
    const endDateMilisecs = new Date(this.bfcConfigurationService.configuration.voteEndDate).getTime();
    const currentDate = new Date(Date.now()).getTime();

    return (endDateMilisecs <= currentDate);
  }
}
